import { DeleteOutlined, EditOutlined, FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { reportRoutes } from '../../../../lib/routes';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  List,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import useSWR from 'swr';
import qs from 'qs';
import ListFilters from '../components/CompanyFormationListFilters';
import steps from '../../../../datasets/company_reports_steps.json';
import ReportsDescriptions from '../components/ReportsDescriptions';
import ReportState from '../components/ReportState';
import AbortModal from '../components/AbortModal';
import useFetch from '../../../../hooks/useFetch';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

const getQuery = (page, limit, filters) => {
  const stepsArray = [];

  if (filters.step) {
    for (let i = 0; i < filters.step.length; i++) {
      if (['BILAN_1', 'BILAN_2', 'BILAN_3', 'BILAN_4'].includes(filters.step[i])) {
        const report = steps.find((item) => item.key === filters.step[i]);

        for (let j = 0; j < report.children.length; j++) {
          stepsArray.push(report.children[j].value);
        }
      } else {
        stepsArray.push(filters.step[i]);
      }
    }
  }

  return qs.stringify(
    {
      steps: stepsArray,
      agencies: filters.agencies,
      search: filters.query,
      group: filters.groups,
      referent: filters.referents,
      formations: filters.trainings,
      reportsType: 'COMPANY',
      excludeArchived: true,
      page: Number(page) - 1,
      limit,
    },
    { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true, arrayFormat: 'comma' },
  );
};

const CompanyReportList = () => {
  const [values, setValues] = useState({
    query: null,
    referents: [],
    groups: [],
    step: [],
    trainings: [],
    agencies: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { get, remove } = useFetch();
  const { user } = useAuth();
  const query = useMemo(() => getQuery(currentPage, pageSize, values), [currentPage, pageSize, values]);
  const {
    data: reports,
    isValidating: isReportsValidating,
    mutate,
  } = useSWR(reportRoutes.default + query, { revalidateOnFocus: false, revalidateOnReconnect: false });

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const deleteReport = async (id) => {
    const results = await remove(reportRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const generatePDF = async (report) => {
    const canAccess =
      report.reports?.length === 4 ? report.step === 'BILAN_4_COMPLÉTÉ' : report.step === 'BILAN_3_COMPLÉTÉ';

    if (canAccess) {
      const key = report._id;

      notification.open({
        message: 'Génération du PDF en cours...',
        description: 'Votre PDF sera prêt dans quelques instants',
        icon: <LoadingOutlined />,
        duration: 0,
        closeIcon: <></>,
        key,
      });

      const results = await get(reportRoutes.groupedPdf + '/' + report._id);

      if (results.status === 201) {
        const blob = new Blob([Buffer.from(results.data)], {
          type: 'application/pdf',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';

        let date = format(
          new Date(report.reports?.length === 4 ? report?.reports[3].finishedAt : report?.reports[2].finishedAt),
          'dd-MM-yyyy',
        );

        link.download = `Ensemble-des-bilans_${report.student.lastName?.toUpperCase()}-${capitalize(
          report.student.firstName,
        )}_${date}.pdf`;
        link.click();
      } else {
        if (results.message) {
          message.error(results.message);
        }
      }

      notification.close(key);
    } else {
      message.error('Tous les bilans ne sont pas complétés');
    }
  };

  return (
    <>
      <AbortModal isOpen={isModalOpen} reportType="COMPANY" onClose={() => setIsModalOpen(false)} onFinish={mutate} />
      <ListFilters values={values} setValues={setValues} steps={steps} />
      <List
        dataSource={reports?.data?.items || []}
        loading={isReportsValidating}
        renderItem={(report) => (
          <Card
            type="inner"
            size="small"
            title={report.student?.lastName?.toUpperCase() + ' ' + capitalize(report.student?.firstName)}
            style={{ width: '100%', marginTop: 16 }}
            bodyStyle={{ padding: 0 }}
            className="report-card"
            extra={
              <Space>
                {report?.step === 'ANNULÉ' ? (
                  <Tooltip title={`Suivi résilié le : ${format(new Date(report.abortDate), 'dd/MM/yyyy')}`}>
                    <Button type="primary" danger>
                      Résilié
                    </Button>
                  </Tooltip>
                ) : (
                  checkAuthorization(user, 'formao', 'companyReports', 'abort') && (
                    <Button danger onClick={() => setIsModalOpen(report._id)}>
                      Résilier
                    </Button>
                  )
                )}
                {report?.step !== 'ANNULÉ' && (
                  <>
                    {checkAuthorization(user, 'formao', 'companyReports', 'download-set') && (
                      <Tooltip title="Télécharger le pdf de l'ensemble des bilans">
                        <Button
                          onClick={() => generatePDF(report)}
                          icon={<FilePdfOutlined />}
                          disabled={
                            report.reports?.length === 4
                              ? report.step !== 'BILAN_4_COMPLÉTÉ'
                              : report.step !== 'BILAN_3_COMPLÉTÉ'
                          }
                        />
                      </Tooltip>
                    )}
                    {checkAuthorization(user, 'formao', 'companyReports', 'update') && (
                      <Link to={`/formao/bilans/bilans-entreprise/modifier-un-bilan/${report._id}`}>
                        <Button icon={<EditOutlined />} />
                      </Link>
                    )}
                    {checkAuthorization(user, 'formao', 'companyReports', 'delete') && (
                      <Tooltip title="Supprimer">
                        <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(report._id)}>
                          <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Tooltip>
                    )}
                  </>
                )}
              </Space>
            }
          >
            {report?.step && !report.step.endsWith('COMPLÉTÉ') && report.step !== 'ANNULÉ' && (
              <>
                <Row style={{ padding: 16 }}>
                  <ReportState
                    data={report}
                    mutate={mutate}
                    canAct={checkAuthorization(user, 'formao', 'companyReports', 'act')}
                  />
                </Row>

                <Divider style={{ margin: 0 }} />
              </>
            )}
            <Descriptions
              size="small"
              labelStyle={{ width: 175, fontWeight: 'bold', fontSize: 16 }}
              style={{ borderBottom: '1px solid #f0f0f0' }}
              className="reports-description"
            >
              <Descriptions.Item label="Responsable du suivi">
                {report?.referent?.last_name?.toUpperCase()} {capitalize(report?.referent?.first_name)}
              </Descriptions.Item>
            </Descriptions>
            <Row>
              <Col style={{ borderRight: '1px solid #f0f0f0' }} span={12}>
                <Descriptions
                  title="Formation"
                  column={2}
                  size="small"
                  className="report-card-formation-alternation"
                  labelStyle={{ width: 125 }}
                  bordered
                >
                  <Descriptions.Item label="Intitulé" span={2}>
                    {report.student?.formation?.title || (
                      <Typography.Text type="danger">Formation introuvable</Typography.Text>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date de début">
                    {report.student?.formation?.startAt &&
                      format(new Date(report.student?.formation.startAt), 'dd/MM/yyyy')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date de fin">
                    {report.student?.formation?.endAt &&
                      format(new Date(report.student?.formation.endAt), 'dd/MM/yyyy')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Centre">
                    {report.student?.agency?.city || (
                      <Typography.Text type="danger">Centre introuvable</Typography.Text>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Groupe">{report.student?.group?.name}</Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={12}>
                <ReportsDescriptions
                  data={report}
                  canDownload={checkAuthorization(user, 'formao', 'companyReports', 'download')}
                />
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row>
              <Col style={{ borderRight: '1px solid #f0f0f0' }} span={8}>
                {report?.student?.lastName ? (
                  <Descriptions
                    className="report-card-student-alternation"
                    title="Stagiaire"
                    column={1}
                    size="small"
                    labelStyle={{ width: 70 }}
                    bordered
                  >
                    <Descriptions.Item label="Nom">{report.student?.lastName?.toUpperCase()}</Descriptions.Item>
                    <Descriptions.Item label="Prénom">{capitalize(report.student?.firstName)}</Descriptions.Item>
                    <Descriptions.Item label="Email">{report.student?.contact?.email}</Descriptions.Item>
                    <Descriptions.Item label="Téléphone">{report.student?.contact?.phone || null}</Descriptions.Item>
                  </Descriptions>
                ) : (
                  <>
                    <Typography.Text className="report-card-description-title">Stagiaire</Typography.Text>
                    <Divider orientation="horizontal" style={{ margin: 0 }} />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucun stagiaire trouvé" />
                  </>
                )}
              </Col>
              <Col style={{ borderRight: '1px solid #f0f0f0' }} span={8}>
                <Descriptions
                  className="report-card-company"
                  title="Entreprise"
                  column={1}
                  size="small"
                  labelStyle={{ width: 160 }}
                  bordered
                >
                  <Descriptions.Item label="Nom de l'entreprise">{report.company?.name}</Descriptions.Item>
                  <Descriptions.Item label="Nom du tuteur">
                    {report.company?.tutor?.lastName?.toUpperCase()}
                  </Descriptions.Item>
                  <Descriptions.Item label="Prénom du tuteur">
                    {capitalize(report.company?.tutor?.firstName)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email du tuteur">{report.company?.tutor?.contact?.email}</Descriptions.Item>
                  <Descriptions.Item label="Téléphone du tuteur">
                    {report.company?.tutor?.contact?.phone || null}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8}>
                {report.student?.former?.last_name ? (
                  <Descriptions
                    className="report-card-former-alternation"
                    title="Formateur"
                    column={1}
                    size="small"
                    labelStyle={{ width: 70 }}
                    bordered
                  >
                    <>
                      <Descriptions.Item label="Nom">
                        {report?.student?.former?.last_name?.toUpperCase()}
                      </Descriptions.Item>
                      <Descriptions.Item label="Prénom">
                        {capitalize(report.student?.former?.first_name)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">{report.student?.former?.email}</Descriptions.Item>
                    </>
                  </Descriptions>
                ) : (
                  <>
                    <Typography.Text className="report-card-description-title">Formateur</Typography.Text>
                    <Divider orientation="horizontal" style={{ margin: 0 }} />
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucun formateur trouvé">
                      <Button>Assigner un formateur</Button>
                    </Empty>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        )}
      />

      <div style={{ textAlign: 'right', marginTop: 16 }}>
        <Pagination
          size="small"
          current={currentPage}
          onChange={setCurrentPage}
          pageSize={pageSize}
          onShowSizeChange={(current, size) => setPageSize(size)}
          total={reports?.data?.totalItems}
          showTotal={(total, range) => {
            return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
          }}
          showSizeChanger
          showQuickJumper
        />
      </div>
    </>
  );
};

export default CompanyReportList;
