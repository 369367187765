import { useState, memo } from 'react';
import { Card, Button, Table, Popconfirm, message, Menu, Dropdown, Tabs, Space, Select, Tag } from 'antd';
import { MoreOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import useSWR from 'swr';
import { studentRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import useFetch from '../../../hooks/useFetch';
import { sort, checkAuthorization } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { useAuth } from '../../../authContext';

const { Item } = Menu;
const { TabPane } = Tabs;

const options = [
  { label: 'Mes stagiaires', value: 'own' },
  { label: 'Tous les stagiaires', value: 'all' },
];

const StudentsList = () => {
  const { data: students, isValidating, mutate } = useSWR(studentRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeView, setActiveView] = useState('own');
  const { put, get } = useFetch();
  const { user } = useAuth();
  const activeStudents = students?.data.filter((student) => !student.disabled) || [];
  const ownActiveStudents = activeStudents.filter((student) => {
    return student.formations.find((formation) => formation.referent === user._id);
  });
  const disabledStudents = students?.data.filter((student) => student.disabled) || [];
  const ownDisabledStudents = disabledStudents.filter((student) => {
    return student.formations.find((formation) => formation.referent === user._id);
  });

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const resetPassword = async (id) => {
    setIsProcessing(true);

    const results = await put(studentRoutes.resetPassword + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const enableAccount = async (id) => {
    setIsProcessing(true);

    const results = await put(studentRoutes.enableAccount + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableAccount = async (id) => {
    setIsProcessing(true);

    const results = await put(studentRoutes.disableAccount + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const downloadRules = async (record) => {
    setIsProcessing(true);

    const results = await get(studentRoutes.downloadRules + '/' + record._id);

    if (results.status === 200) {
      const blob = new Blob([Buffer.from(results.data)], {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      const date = moment(record.rulesAccepted.date).format('DD-MM-YYYY');
      const lastName = record.last_name.toUpperCase();
      // prettier-ignore
      const firstName = record.first_name.charAt(0).toUpperCase() + record.first_name.slice(1)

      link.download = `reglement-interieur_${lastName}-${firstName}_${date}-.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ record }) => (
    <Menu key={`menu-${record._id}`}>
      {checkAuthorization(user, 'formao', 'students', 'reset-password') && (
        <Item key="reset">
          <Popconfirm {...popconfirmProps} onConfirm={() => resetPassword(record._id)}>
            Réinitialiser le mot de passe
          </Popconfirm>
        </Item>
      )}
      {checkAuthorization(user, 'formao', 'students', 'update-student') && (
        <Item key="edit">
          <Link to={`/formao/apprenants/modifier-un-apprenant/${record._id}`}>Modifier</Link>
        </Item>
      )}
      {record.disabled
        ? checkAuthorization(user, 'formao', 'students', 'enable-student') && (
            <Item key="enable">
              <Popconfirm {...popconfirmProps} onConfirm={() => enableAccount(record._id)}>
                Activer le compte
              </Popconfirm>
            </Item>
          )
        : checkAuthorization(user, 'formao', 'students', 'disable-student') && (
            <Item key="disable">
              <Popconfirm {...popconfirmProps} onConfirm={() => disableAccount(record._id)}>
                Désactiver le compte
              </Popconfirm>
            </Item>
          )}
    </Menu>
  ));

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => sort(a, b, 'last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
      render: (value) => value.toUpperCase(),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => sort(a, b, 'first_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('first_name'),
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1),
    },
    {
      title: "Nom d'utilisateur",
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => sort(a, b, 'username'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => sort(a, b, 'email'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Date de création',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    {
      title: 'Règlement intérieur',
      dataIndex: 'rulesAccepted',
      key: 'rulesAccepted',
      align: 'center',
      width: 150,
      render: (value, record) => {
        if (value) {
          return (
            <Button icon={<FilePdfOutlined />} block onClick={() => downloadRules(record)} size="small">
              Télécharger
            </Button>
          );
        }

        return (
          <Tag color="error" style={{ width: '100%' }}>
            Non signé
          </Tag>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'formao', 'students', 'create-student') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Space>
            <Button className="gray-text">
              <Link to="/formao/apprenants/import">Importer</Link>
            </Button>
            <Button className="gray-text">
              <Link to="/formao/apprenants/nouvel-apprenant">Nouvel apprenant</Link>
            </Button>
          </Space>
        </div>
      )}
      <Tabs tabBarExtraContent={{ right: <Select options={options} value={activeView} onChange={setActiveView} /> }}>
        {activeView === 'own' && (
          <>
            <TabPane tab={`Actifs (${ownActiveStudents.length})`} key="own-enabled">
              <Table
                loading={!students || isValidating || isProcessing}
                dataSource={ownActiveStudents}
                columns={columns}
                bordered
                size="small"
                rowKey={(row) => row._id}
                pagination={tablePagination(ownActiveStudents)}
              />
            </TabPane>
            <TabPane tab={`Inactifs (${ownDisabledStudents.length})`} key="own-disabled">
              <Table
                loading={!students || isValidating || isProcessing}
                dataSource={ownDisabledStudents}
                columns={columns}
                bordered
                size="small"
                rowKey={(row) => row._id}
                pagination={tablePagination(ownDisabledStudents)}
              />
            </TabPane>
          </>
        )}
        {activeView === 'all' && (
          <>
            <TabPane tab={`Actifs (${activeStudents.length})`} key="all-enabled">
              <Table
                loading={!students || isValidating || isProcessing}
                dataSource={activeStudents}
                columns={columns}
                bordered
                size="small"
                rowKey={(row) => row._id}
                pagination={tablePagination(activeStudents)}
              />
            </TabPane>
            <TabPane tab={`Inactifs (${disabledStudents.length})`} key="all-disabled">
              <Table
                loading={!students || isValidating || isProcessing}
                dataSource={disabledStudents}
                columns={columns}
                bordered
                size="small"
                rowKey={(row) => row._id}
                pagination={tablePagination(disabledStudents)}
              />
            </TabPane>
          </>
        )}
      </Tabs>
    </Card>
  );
};

export default StudentsList;
