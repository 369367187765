import { Button, List, Popconfirm, Space, Table, Tag, Tooltip, message, notification } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { format, getDate, getDaysInMonth, subMonths } from 'date-fns';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { useState } from 'react';
import useSWR from 'swr';
import { agencyRoutes, groupRoutes, reportRoutes } from '../../../../lib/routes';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import { sort } from '../../../../shared/utils';
import useColumnSearch from '../../../../hooks/useColumnSearch';
import tablePagination from '../../../../lib/tablePagination';
import useFetch from '../../../../hooks/useFetch';
import AbortModal from '../components/AbortModal';

const MonthlyReportList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { data: groups } = useSWR(groupRoutes.default);
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    data: reports,
    isValidating: isReportsValidating,
    mutate,
  } = useSWR(reportRoutes.default + '?reportsType=MENSUEL&excludeArchived=true', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { get, remove } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const deleteReport = async (id) => {
    setIsProcessing(true);

    const results = await remove(reportRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const generatePDF = async (data, report, formattedDate) => {
    const key = report._id;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await get(reportRoutes.pdf + '/' + report.results);

    if (results.status === 201) {
      const blob = new Blob([Buffer.from(results.data)], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      link.download = `Suivi-mensuel-du-${formattedDate}_${data.student.lastName.toUpperCase()}-${capitalize(
        data.student.firstName,
      )}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  const columns = [
    {
      title: 'Parcours',
      dataIndex: 'company',
      key: 'format',
      align: 'center',
      width: 100,
      sorter: (a, b) => (a?.company?.name && b?.company?.name ? 1 : -1),
      render: (record) => <Tag>{record?.name ? 'ALTERNANCE' : 'CONTINU'}</Tag>,
    },
    {
      title: 'Stagiaire',
      dataIndex: 'student',
      key: 'student',
      sorter: (a, b) => sort(a, b, 'student.lastName', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('student.lastName'),
      render: (record) => record?.lastName?.toUpperCase() + ' ' + capitalize(record?.firstName),
    },
    {
      title: 'Formateur',
      dataIndex: ['student', 'former'],
      key: 'former',
      sorter: (a, b) => sort(a, b, 'last_name', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
      render: (record) => record?.last_name?.toUpperCase() + ' ' + capitalize(record?.first_name),
    },
    {
      title: 'Formation',
      dataIndex: ['student', 'formation', 'title'],
      key: 'formationTitle',
      sorter: (a, b) => sort(a, b, 'student.formation.title', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('student.formation.title'),
    },
    {
      title: 'Date de début',
      dataIndex: ['student', 'formation', 'startAt'],
      key: 'formationStartAt',
      sorter: (a, b) => sort(a, b, 'student.formation.startAt', ''),
      render: (record) => record && format(new Date(record), 'dd/MM/yyyy'),
    },
    {
      title: 'Date de fin',
      dataIndex: ['student', 'formation', 'endAt'],
      key: 'formationEndAt',
      sorter: (a, b) => sort(a, b, 'student.formation.endAt', ''),
      render: (record) => record && format(new Date(record), 'dd/MM/yyyy'),
    },
    {
      title: 'Centre',
      dataIndex: ['student', 'agency', 'city'],
      key: 'formationAgency',
      sorter: (a, b) => sort(a, b, 'student.agency.city', 'Centre introuvable'),
      sortDirections: ['ascend', 'descend'],
      filters: agencies?.data.map((agency) => ({ text: agency.city, value: agency._id })) || [],
      onFilter: (value, record) => record.student.agency._id === value,
    },
    {
      title: 'Groupe',
      dataIndex: ['student', 'group', 'name'],
      key: 'formationGroup',
      sorter: (a, b) => sort(a, b, 'student.group.name', ''),
      sortDirections: ['ascend', 'descend'],
      filters: groups?.data.map((group) => ({ text: group.name, value: group._id })) || [],
      onFilter: (value, record) => record.student?.group?._id === value,
    },
    ...(checkAuthorization(user, 'formao', 'monthlyReports', 'download-set') ||
    checkAuthorization(user, 'formao', 'monthlyReports', 'update') ||
    checkAuthorization(user, 'formao', 'monthlyReports', 'delete')
      ? [
          {
            key: 'actions',
            width: 50,
            render: (record, report) => (
              <Space className="monthly-reports-actions" style={{ width: '100%' }}>
                {report?.step === 'ANNULÉ' ? (
                  <Tooltip title={`Suivi résilié le : ${format(new Date(report.abortDate), 'dd/MM/yyyy')}`}>
                    <Button type="primary" danger style={{ width: '100%' }}>
                      Résilié
                    </Button>
                  </Tooltip>
                ) : (
                  checkAuthorization(user, 'formao', 'monthlyReports', 'abort') && (
                    <Button danger onClick={() => setIsModalOpen(report._id)}>
                      Résilier
                    </Button>
                  )
                )}
                {report?.step !== 'ANNULÉ' && (
                  <>
                    {checkAuthorization(user, 'formao', 'monthlyReports', 'update') && (
                      <Link to={`/formao/bilans/suivis-mensuels/modifier-un-bilan/${record._id}`}>
                        <Button icon={<EditOutlined />} />
                      </Link>
                    )}
                    {checkAuthorization(user, 'formao', 'monthlyReports', 'delete') && (
                      <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(record._id)}>
                        <Button icon={<DeleteOutlined />} />
                      </Popconfirm>
                    )}
                  </>
                )}
              </Space>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <AbortModal isOpen={isModalOpen} reportType="MENSUEL" onClose={() => setIsModalOpen(false)} onFinish={mutate} />

      <Table
        dataSource={reports?.data || []}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination([])}
        disabled={!reports || isProcessing}
        loading={isReportsValidating}
        expandable={{
          expandedRowRender: (record) => (
            <List
              dataSource={record?.reports || []}
              size="small"
              renderItem={(item) => {
                const daysInMonth = getDaysInMonth(new Date(item.sendAt));
                const day = getDate(new Date(item.sendAt));
                const date = day <= daysInMonth / 2 ? subMonths(new Date(item.sendAt), 1) : new Date(item.sendAt);
                const formattedDate = format(date, 'MM/yyyy');

                return (
                  <List.Item
                    actions={
                      item?.finishedAt && checkAuthorization(user, 'formao', 'monthlyReports', 'download')
                        ? [
                            <Button
                              style={{ padding: 0, height: 22 }}
                              type="link"
                              disabled={!item?.finishedAt}
                              onClick={() => generatePDF(record, item, formattedDate)}
                            >
                              Télécharger
                            </Button>,
                          ]
                        : []
                    }
                  >
                    Bilan du {formattedDate}
                  </List.Item>
                );
              }}
            />
          ),
        }}
      />
    </>
  );
};

export default MonthlyReportList;
