import { DeleteOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  List,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import ReportsDescriptions from '../../components/ReportsDescriptions';
import ReportState from '../../components/ReportState';
import AbortModal from '../../components/AbortModal';
import { checkAuthorization } from '../../../../../shared/utils';
import { useAuth } from '../../../../../authContext';

const CompanyReportList = ({ data, mutate, disabled, deleteReport }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const generatePDF = () => {};

  return (
    <>
      <AbortModal isOpen={isModalOpen} reportType="COMPANY" onClose={() => setIsModalOpen(false)} onFinish={mutate} />
      {(data?.items || []).length === 0 ? (
        <Empty />
      ) : (
        <List
          dataSource={data?.items || []}
          disabled={disabled}
          renderItem={(report) => (
            <Card
              type="inner"
              size="small"
              title={report.student?.lastName?.toUpperCase() + ' ' + capitalize(report.student?.firstName)}
              style={{ width: '100%', marginTop: 16 }}
              bodyStyle={{ padding: 0 }}
              className="report-card"
              extra={
                <Space>
                  {report?.step === 'ANNULÉ' ? (
                    <Tooltip title={`Suivi résilié le : ${format(new Date(report.abortDate), 'dd/MM/yyyy')}`}>
                      <Button type="primary" danger>
                        Résilié
                      </Button>
                    </Tooltip>
                  ) : (
                    checkAuthorization(user, 'formao', 'companyReports', 'abort') && (
                      <Button danger onClick={() => setIsModalOpen(report._id)}>
                        Résilier
                      </Button>
                    )
                  )}
                  {report?.step !== 'ANNULÉ' && (
                    <>
                      <Tooltip title="Télécharger le pdf de l'ensemble des bilans">
                        <Button
                          onClick={() => generatePDF(report)}
                          icon={<FilePdfOutlined />}
                          disabled={report.step !== 'BILAN_2_COMPLÉTÉ'}
                        />
                      </Tooltip>
                      <Link to={`/formao/bilans/bilans-de-formation/modifier-un-bilan/${report._id}`}>
                        <Button icon={<EditOutlined />} />
                      </Link>
                      <Tooltip title="Supprimer">
                        <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(report._id)}>
                          <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Tooltip>
                    </>
                  )}
                </Space>
              }
            >
              {report?.step && !report.step.endsWith('COMPLÉTÉ') && report.step !== 'ANNULÉ' && (
                <>
                  <Row style={{ padding: 16 }}>
                    <ReportState data={report} mutate={mutate} />
                  </Row>

                  <Divider style={{ margin: 0 }} />
                </>
              )}
              <Descriptions
                size="small"
                labelStyle={{ width: 175, fontWeight: 'bold', fontSize: 16 }}
                style={{ borderBottom: '1px solid #f0f0f0' }}
              >
                <Descriptions.Item label="Responsable du suivi">
                  {report?.referent?.last_name?.toUpperCase()} {capitalize(report?.referent?.first_name)}
                </Descriptions.Item>
              </Descriptions>
              <Row>
                <Col style={{ borderRight: '1px solid #f0f0f0' }} span={12}>
                  <Descriptions
                    title="Formation"
                    column={2}
                    size="small"
                    className="report-card-formation-alternation"
                    labelStyle={{ width: 125 }}
                    bordered
                  >
                    <Descriptions.Item label="Intitulé" span={2}>
                      {report.student?.formation?.title || (
                        <Typography.Text type="danger">Formation introuvable</Typography.Text>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date de début">
                      {report.student?.formation?.startAt &&
                        format(new Date(report.student?.formation.startAt), 'dd/MM/yyyy')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date de fin">
                      {report.student?.formation?.endAt &&
                        format(new Date(report.student?.formation.endAt), 'dd/MM/yyyy')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Centre">
                      {report.student?.agency?.city || (
                        <Typography.Text type="danger">Centre introuvable</Typography.Text>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Groupe">{report.student?.group?.name}</Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={12}>
                  <ReportsDescriptions data={report} />
                </Col>
              </Row>
              <Divider style={{ margin: 0 }} />
              <Row>
                <Col style={{ borderRight: '1px solid #f0f0f0' }} span={8}>
                  {report?.student?.lastName ? (
                    <Descriptions
                      className="report-card-student-alternation"
                      title="Stagiaire"
                      column={1}
                      size="small"
                      labelStyle={{ width: 70 }}
                      bordered
                    >
                      <Descriptions.Item label="Nom">{report.student?.lastName?.toUpperCase()}</Descriptions.Item>
                      <Descriptions.Item label="Prénom">{capitalize(report.student?.firstName)}</Descriptions.Item>
                      <Descriptions.Item label="Email">{report.student?.contact?.email}</Descriptions.Item>
                      <Descriptions.Item label="Téléphone">{report.student?.contact?.phone || null}</Descriptions.Item>
                    </Descriptions>
                  ) : (
                    <>
                      <Typography.Text className="report-card-description-title">Stagiaire</Typography.Text>
                      <Divider orientation="horizontal" style={{ margin: 0 }} />
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucun stagiaire trouvé" />
                    </>
                  )}
                </Col>
                <Col style={{ borderRight: '1px solid #f0f0f0' }} span={8}>
                  <Descriptions
                    className="report-card-company"
                    title="Entreprise"
                    column={1}
                    size="small"
                    labelStyle={{ width: 160 }}
                    bordered
                  >
                    <Descriptions.Item label="Nom de l'entreprise">{report.company?.name}</Descriptions.Item>
                    <Descriptions.Item label="Nom du tuteur">
                      {report.company?.tutor?.lastName?.toUpperCase()}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prénom du tuteur">
                      {capitalize(report.company?.tutor?.firstName)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email du tuteur">
                      {report.company?.tutor?.contact?.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone du tuteur">
                      {report.company?.tutor?.contact?.phone || null}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={8}>
                  {report.student?.former?.last_name ? (
                    <Descriptions
                      className="report-card-former-alternation"
                      title="Formateur"
                      column={1}
                      size="small"
                      labelStyle={{ width: 70 }}
                      bordered
                    >
                      <>
                        <Descriptions.Item label="Nom">
                          {report.student?.former?.last_name?.toUpperCase()}
                        </Descriptions.Item>
                        <Descriptions.Item label="Prénom">
                          {capitalize(report.student?.former?.first_name)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">{report.student?.former?.email}</Descriptions.Item>
                        <Descriptions.Item label="Téléphone">{report.student?.former?.phone || null}</Descriptions.Item>
                      </>
                    </Descriptions>
                  ) : (
                    <>
                      <Typography.Text className="report-card-description-title">Formateur</Typography.Text>
                      <Divider orientation="horizontal" style={{ margin: 0 }} />
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucun formateur trouvé">
                        <Button>Assigner un formateur</Button>
                      </Empty>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          )}
        />
      )}
    </>
  );
};

export default CompanyReportList;
