import { useState } from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { format, getDate, getDaysInMonth, subMonths } from 'date-fns';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm, Space, Table, Tooltip, message, notification } from 'antd';
import useSWR from 'swr';
import { agencyRoutes, groupRoutes, reportRoutes } from '../../../../../lib/routes';
import { checkAuthorization, sort } from '../../../../../shared/utils';
import { useAuth } from '../../../../../authContext';
import useColumnSearch from '../../../../../hooks/useColumnSearch';
import useFetch from '../../../../../hooks/useFetch';
import AbortModal from '../../components/AbortModal';

const MonthlyReportList = ({ data, mutate, deleteReport, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { data: groups } = useSWR(groupRoutes.default);
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { get } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const columns = [
    {
      title: 'Stagiaire',
      dataIndex: 'student',
      key: 'student',
      sorter: (a, b) => sort(a, b, 'student.lastName', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('student.lastName'),
      render: (record) => record?.lastName?.toUpperCase() + ' ' + capitalize(record?.firstName),
    },
    {
      title: 'Formateur',
      dataIndex: ['student', 'former'],
      key: 'former',
      sorter: (a, b) => sort(a, b, 'last_name', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('last_name'),
      render: (record) => record?.last_name?.toUpperCase() + ' ' + capitalize(record?.first_name),
    },
    {
      title: 'Formation',
      dataIndex: ['student', 'formation', 'title'],
      key: 'formationTitle',
      sorter: (a, b) => sort(a, b, 'student.formation.title', ''),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('student.formation.title'),
    },
    {
      title: 'Date de début',
      dataIndex: ['student', 'formation', 'startAt'],
      key: 'formationStartAt',
      sorter: (a, b) => sort(a, b, 'student.formation.startAt', ''),
      render: (record) => format(new Date(record), 'dd/MM/yyyy'),
    },
    {
      title: 'Date de fin',
      dataIndex: ['student', 'formation', 'endAt'],
      key: 'formationEndAt',
      sorter: (a, b) => sort(a, b, 'student.formation.endAt', ''),
      render: (record) => format(new Date(record), 'dd/MM/yyyy'),
    },
    {
      title: 'Centre',
      dataIndex: ['student', 'agency', 'city'],
      key: 'formationAgency',
      sorter: (a, b) => sort(a, b, 'student.agency.city', 'Centre introuvable'),
      sortDirections: ['ascend', 'descend'],
      filters: agencies?.data.map((agency) => ({ text: agency.city, value: agency._id })) || [],
      onFilter: (value, record) => record.student.agency._id === value,
    },
    {
      title: 'Groupe',
      dataIndex: ['student', 'group', 'name'],
      key: 'formationGroup',
      sorter: (a, b) => sort(a, b, 'student.group.name', ''),
      sortDirections: ['ascend', 'descend'],
      filters: groups?.data.map((group) => ({ text: group.name, value: group._id })) || [],
      onFilter: (value, record) => record.student?.group?._id === value,
    },
    {
      key: 'actions',
      width: 50,
      render: (record, report) => (
        <Space>
          {report?.step === 'ANNULÉ' ? (
            <Tooltip title={`Suivi résilié le : ${format(new Date(report.abortDate), 'dd/MM/yyyy')}`}>
              <Button type="primary" danger>
                Résilié
              </Button>
            </Tooltip>
          ) : (
            checkAuthorization(user, 'formao', 'monthlyReports', 'abort') && (
              <Button danger onClick={() => setIsModalOpen(report._id)}>
                Résilier
              </Button>
            )
          )}
          {report?.step !== 'ANNULÉ' && (
            <>
              <Link to={`/formao/bilans/suivis-mensuels/modifier-un-bilan/${record._id}`}>
                <Button icon={<EditOutlined />} />
              </Link>
              <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(record._id)}>
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  const generatePDF = async (data, report, formattedDate) => {
    const key = report._id;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await get(reportRoutes.pdf + '/' + report.results);

    if (results.status === 201) {
      const blob = new Blob([Buffer.from(results.data)], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      link.download = `Suivi-mensuel-du-${formattedDate}_${data.student.lastName.toUpperCase()}-${capitalize(
        data.student.firstName,
      )}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  return (
    <>
      <AbortModal isOpen={isModalOpen} reportType="MENSUEL" onClose={() => setIsModalOpen(false)} onFinish={mutate} />

      <Table
        dataSource={data?.items || []}
        columns={columns}
        pagination={false}
        bordered
        size="small"
        rowKey={(row) => row._id}
        disabled={!data?.items || disabled}
        expandable={{
          expandedRowRender: (record) => (
            <List
              dataSource={record?.reports || []}
              size="small"
              renderItem={(item) => {
                const daysInMonth = getDaysInMonth(new Date(item.sendAt));
                const day = getDate(new Date(item.sendAt));
                const date = day <= daysInMonth / 2 ? subMonths(new Date(item.sendAt), 1) : new Date(item.sendAt);
                const formattedDate = format(date, 'MM/yyyy');

                return (
                  <List.Item
                    actions={
                      item?.finishedAt
                        ? [
                            <Button
                              style={{ padding: 0, height: 22 }}
                              type="link"
                              disabled={!item?.finishedAt}
                              onClick={() => generatePDF(record, item, formattedDate)}
                            >
                              Télécharger
                            </Button>,
                          ]
                        : []
                    }
                  >
                    Bilan du {formattedDate}
                  </List.Item>
                );
              }}
            />
          ),
        }}
      />
    </>
  );
};

export default MonthlyReportList;
