import { Button, Empty, Form, Input, Pagination, Select, Space, Tabs, message } from 'antd';
import { useState } from 'react';
import qs from 'qs';
import useFetch from '../../../../hooks/useFetch';
import FormationReportList from './components/FormationReportList';
import CompanyReportList from './components/CompanyReportList';
import TrainingReportList from './components/TrainingReportList';
import MonthlyReportList from './components/MonthlyReportList';
import SatisfactionReportList from './components/SatisfactionReportList';
import { reportRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

const reportsTypes = [
  { label: 'Entreprise (ALT)', value: 'COMPANY' },
  { label: 'Formation (TC)', value: 'FORMATION' },
  { label: 'Mensuel', value: 'MENSUEL' },
  { label: 'Satisfaction', value: 'SATISFACTION' },
  { label: 'Stage', value: 'STAGE' },
];

const getQuery = (page, limit, filters) =>
  qs.stringify(
    {
      search: filters.query,
      reportsType: filters.reportsType,
      page: Number(page) - 1,
      limit,
    },
    { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true, arrayFormat: 'comma' },
  );

const Search = () => {
  const [tab, setTab] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [values, setValues] = useState({ query: null, reportsType: [], string: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [reports, setReports] = useState(null);
  const { get, remove } = useFetch();
  const { user } = useAuth();

  const handleTab = async (value) => {
    if (currentPage !== 1 && value !== tab) {
      setCurrentPage(1);
      await getReports(1, pageSize);
    }

    setTab(value);
  };

  const search = async () => {
    setCurrentPage(1);
    setTab(null);

    await getReports(1, pageSize, true);
  };

  const getReports = async (page, elemPerPage, isSearching) => {
    setIsProcessing(true);

    if (values.query || values.reportsType?.length > 0) {
      const results = await get(reportRoutes.search + getQuery(page, elemPerPage, values));

      if (results.status === 200) {
        setReports(results.data);

        if (isSearching) {
          if (values.reportsType.length === 0 || values.reportsType.includes('FORMATION')) {
            setTab('FORMATION');
          } else if (values.reportsType.includes('COMPANY')) {
            setTab('COMPANY');
          } else if (values.reportsType.includes('STAGE')) {
            setTab('STAGE');
          } else if (values.reportsType.includes('MENSUEL')) {
            setTab('MENSUEL');
          } else if (values.reportsType.includes('SATISFACTION')) {
            setTab('SATISFACTION');
          }
        }
      } else {
        if (results.message) {
          message.error(results.message);
        }
      }
    } else {
      setTab(null);
      setReports(null);
    }

    setIsProcessing(false);
  };

  const deleteReport = async (id) => {
    const results = await remove(reportRoutes.default + '/' + id);

    if (results.status === 200) {
      await getReports(currentPage, pageSize);
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  return (
    <>
      <Form className="reports-search-container">
        <Space align="start">
          <Form.Item className="reports-search-input">
            <Input
              style={{ width: 250 }}
              placeholder="Recherche"
              value={values.query}
              onChange={(e) => setValues((rest) => ({ ...rest, query: e.target.value === '' ? null : e.target.value }))}
              allowClear
              onPressEnter={search}
              disabled={isProcessing}
            />
          </Form.Item>
          <Form.Item className="reports-search-input">
            <Select
              placeholder="Types de bilans"
              mode="multiple"
              style={{ minWidth: 150, maxWidth: 350 }}
              value={values.reportsType}
              onChange={(e) => setValues({ ...values, reportsType: e })}
              options={reportsTypes || []}
              allowClear
              disabled={isProcessing}
            />
          </Form.Item>

          <Button
            onClick={() =>
              setValues({
                query: null,
                reportsType: [],
                string: '',
              })
            }
            loading={isProcessing}
          >
            Vider
          </Button>
          <Button type="primary" onClick={search} loading={isProcessing}>
            Rechercher
          </Button>
        </Space>
      </Form>

      <Tabs activeKey={tab} onChange={handleTab}>
        {checkAuthorization(user, 'formao', 'formationReports', 'search') && (
          <Tabs.TabPane
            tab={`Formation en continu (${reports?.formation?.totalItems || 0})`}
            key="FORMATION"
            disabled={!(reports?.formation?.totalItems > 0)}
          >
            <FormationReportList
              data={reports?.formation}
              mutate={() => getReports(currentPage, pageSize)}
              deleteReport={deleteReport}
              disabled={!(reports?.formation?.totalItems > 0) || isProcessing}
            />

            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <Pagination
                size="small"
                current={currentPage}
                onChange={(value) => {
                  setCurrentPage(value);
                  getReports(value, pageSize);
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  getReports(currentPage, pageSize);
                }}
                total={reports?.formation?.totalItems}
                showTotal={(total, range) => {
                  return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                }}
                showSizeChanger
                showQuickJumper
              />
            </div>
          </Tabs.TabPane>
        )}
        {checkAuthorization(user, 'formao', 'companyReports', 'search') && (
          <Tabs.TabPane
            tab={`Formation en alternance (${reports?.company?.totalItems || 0})`}
            key="COMPANY"
            disabled={!(reports?.company?.totalItems > 0)}
          >
            <CompanyReportList
              data={reports?.company}
              mutate={() => getReports(currentPage, pageSize)}
              deleteReport={deleteReport}
              disabled={!(reports?.company?.totalItems > 0) || isProcessing}
            />

            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <Pagination
                size="small"
                current={currentPage}
                onChange={(value) => {
                  setCurrentPage(value);
                  getReports(value, pageSize);
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  getReports(currentPage, pageSize);
                }}
                total={reports?.company?.totalItems}
                showTotal={(total, range) => {
                  return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                }}
                showSizeChanger
                showQuickJumper
              />
            </div>
          </Tabs.TabPane>
        )}
        {checkAuthorization(user, 'formao', 'trainingReports', 'search') && (
          <Tabs.TabPane
            tab={`Stage (${reports?.training?.totalItems || 0})`}
            key="STAGE"
            disabled={!(reports?.training?.totalItems > 0)}
          >
            <TrainingReportList
              data={reports?.training}
              mutate={() => getReports(currentPage, pageSize)}
              deleteReport={deleteReport}
              disabled={!(reports?.training?.totalItems > 0) || isProcessing}
            />

            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <Pagination
                size="small"
                current={currentPage}
                onChange={(value) => {
                  setCurrentPage(value);
                  getReports(value, pageSize);
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  getReports(currentPage, pageSize);
                }}
                total={reports?.training?.totalItems}
                showTotal={(total, range) => {
                  return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                }}
                showSizeChanger
                showQuickJumper
              />
            </div>
          </Tabs.TabPane>
        )}
        {checkAuthorization(user, 'formao', 'monthlyReports', 'search') && (
          <Tabs.TabPane
            tab={`Mensuel (${reports?.monthly?.totalItems || 0})`}
            key="MENSUEL"
            disabled={!(reports?.monthly?.totalItems > 0)}
          >
            <MonthlyReportList
              data={reports?.monthly}
              mutate={() => getReports(currentPage, pageSize)}
              deleteReport={deleteReport}
              disabled={!(reports?.monthly?.totalItems > 0) || isProcessing}
            />

            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <Pagination
                size="small"
                current={currentPage}
                onChange={(value) => {
                  setCurrentPage(value);
                  getReports(value, pageSize);
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  getReports(currentPage, pageSize);
                }}
                total={reports?.monthly?.totalItems}
                showTotal={(total, range) => {
                  return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                }}
                showSizeChanger
                showQuickJumper
              />
            </div>
          </Tabs.TabPane>
        )}
        {checkAuthorization(user, 'formao', 'satisfactionReports', 'search') && (
          <Tabs.TabPane
            tab={`Satisfaction (${reports?.satisfaction?.totalItems || 0})`}
            key="SATISFACTION"
            disabled={!(reports?.satisfaction?.totalItems > 0)}
          >
            <SatisfactionReportList
              data={reports?.satisfaction}
              mutate={() => getReports(currentPage, pageSize)}
              deleteReport={deleteReport}
              disabled={!(reports?.satisfaction?.totalItems > 0) || isProcessing}
            />

            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <Pagination
                size="small"
                current={currentPage}
                onChange={(value) => {
                  setCurrentPage(value);
                  getReports(value, pageSize);
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  getReports(currentPage, pageSize);
                }}
                total={reports?.satisfaction?.totalItems}
                showTotal={(total, range) => {
                  return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                }}
                showSizeChanger
                showQuickJumper
              />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
      {reports === null || !reports ? <Empty /> : null}
    </>
  );
};

export default Search;
