import { memo } from 'react';
import { format } from 'date-fns';
import { Descriptions, Typography, Space, Tooltip, Button, message, notification } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import useFetch from '../../../../hooks/useFetch';
import { reportRoutes } from '../../../../lib/routes';

const ReportsDescriptions = memo(({ data, canDownload }) => {
  const { step } = data;
  const report = step?.split('_')[1];
  const { get } = useFetch();

  const generatePdf = async (index, formation, report) => {
    const key = index;

    notification.open({
      message: 'Génération du PDF en cours...',
      description: 'Votre PDF sera prêt dans quelques instants',
      icon: <LoadingOutlined />,
      duration: 0,
      closeIcon: <></>,
      key,
    });

    const results = await get(reportRoutes.pdf + '/' + report.results);

    if (results.status === 201) {
      const blob = new Blob([Buffer.from(results.data)], {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      let date = format(new Date(report?.finishedAt), 'dd-MM-yyyy');

      let reportName = '';
      if (index === 0) {
        reportName = formation === 'COMPANY' ? 'Bilan-d-integration' : 'Bilan-mi-parcours';
      }

      if (index === 1) {
        reportName = formation === 'COMPANY' ? 'Bilan-mi-parcours' : 'Bilan-final';
      }

      if (index === 2) {
        reportName = 'Bilan-final';
      }

      if (index === 3) {
        reportName = 'Bilan-supplémentaire';
      }

      link.download = `${reportName}_${data.student.lastName.toUpperCase()}-${
        data.student.firstName.charAt(0).toUpperCase() + data.student.firstName.slice(1)
      }_${date}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    notification.close(key);
  };

  const getContent = (index) => {
    if (data?.reports[index]?.ignored) {
      return (
        <Space>
          <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
          <Typography.Text>Bilan ignoré</Typography.Text>
        </Space>
      );
    } else {
      if (step) {
        if (step === 'ANNULÉ') {
          if (data?.reports[index]?.finishedAt) {
            return (
              <Space>
                <CheckCircleOutlined style={{ color: '#52c41a' }} />
                <Typography.Text> {format(new Date(data?.reports[index]?.finishedAt), 'dd/MM/yyyy')}</Typography.Text>

                {canDownload && (
                  <>
                    {' -'}
                    <Tooltip title="Télécharger le pdf de ce bilan">
                      <Button
                        style={{ padding: 0, height: 22 }}
                        type="link"
                        onClick={() => generatePdf(index, data?.reportsType, data?.reports[index])}
                        disabled={!data?.reports[index]?.results}
                      >
                        Télécharger
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Space>
            );
          } else {
            return (
              <Space>
                <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                <Typography.Text>Suivi résilié</Typography.Text>
              </Space>
            );
          }
        } else {
          if (index + 1 === Number(report)) {
            if (step.endsWith('COMPLÉTÉ')) {
              return (
                <Space>
                  <CheckCircleOutlined style={{ color: '#52c41a' }} />
                  <Typography.Text> {format(new Date(data?.reports[index]?.finishedAt), 'dd/MM/yyyy')}</Typography.Text>

                  {canDownload && (
                    <>
                      {' -'}
                      <Tooltip title="Télécharger le pdf de ce bilan">
                        <Button
                          style={{ padding: 0, height: 22 }}
                          type="link"
                          onClick={() => generatePdf(index, data?.reportsType, data?.reports[index])}
                          disabled={!data?.reports[index]?.results}
                        >
                          Télécharger
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Space>
              );
            } else {
              return (
                <Space>
                  <SyncOutlined />
                  <Typography.Text> En cours </Typography.Text>
                </Space>
              );
            }
          }
          if (index + 1 < Number(report)) {
            return (
              <Space>
                <CheckCircleOutlined style={{ color: '#52c41a' }} />
                <Typography.Text> {format(new Date(data?.reports[index]?.finishedAt), 'dd/MM/yyyy')}</Typography.Text>

                {canDownload && (
                  <>
                    {' -'}
                    <Tooltip title="Télécharger le pdf de ce bilan">
                      <Button
                        style={{ padding: 0, height: 22 }}
                        type="link"
                        onClick={() => generatePdf(index, data?.reportsType, data?.reports[index])}
                        disabled={!data?.reports[index]?.results}
                      >
                        Télécharger
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Space>
            );
          }
          if (index + 1 > Number(report)) {
            if (data?.reports[index]?.sendAt) {
              return (
                <Space>
                  <ClockCircleOutlined style={{ color: '#faad14' }} />

                  <Typography.Text>
                    Programmé le {format(new Date(data?.reports[index]?.sendAt), 'dd/MM/yyyy')}
                  </Typography.Text>
                </Space>
              );
            } else {
              return (
                <Space>
                  <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                  <Typography.Text>Non programmé</Typography.Text>
                </Space>
              );
            }
          }
        }
      } else {
        if (data?.reports[index]?.sendAt) {
          return (
            <Space>
              <ClockCircleOutlined style={{ color: '#faad14' }} />

              <Typography.Text>
                Programmé le {format(new Date(data?.reports[index]?.sendAt), 'dd/MM/yyyy')}
              </Typography.Text>
            </Space>
          );
        } else {
          return (
            <Space>
              <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
              <Typography.Text>Non programmé</Typography.Text>
            </Space>
          );
        }
      }
    }
  };

  return (
    <Descriptions
      className={
        data.reportsType === 'COMPANY' ? 'report-card-reports-alternation' : 'report-card-reports-uninterrupted'
      }
      title="Bilans"
      column={2}
      size="small"
      labelStyle={{ width: 130 }}
      bordered
    >
      <Descriptions.Item label={data.reportsType === 'COMPANY' ? 'Intégration' : 'Mi-parcours'} span={2}>
        {getContent(0)}
      </Descriptions.Item>
      <Descriptions.Item label={data.reportsType === 'COMPANY' ? 'Mi-parcours' : 'Final'} span={2}>
        {getContent(1)}
      </Descriptions.Item>
      {data.reportsType === 'COMPANY' && [
        <Descriptions.Item key="FINAL" label="Final" span={2}>
          {getContent(2)}
        </Descriptions.Item>,
        <Descriptions.Item key="FACULTATIVE" label="Supplémentaire" span={2}>
          {getContent(3)}
        </Descriptions.Item>,
      ]}
    </Descriptions>
  );
});

export default ReportsDescriptions;
