import { capitalize } from 'lodash';
import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Tag, Tooltip, Typography } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import { checkAuthorization, sort } from '../../../../../shared/utils';
import { agencyRoutes } from '../../../../../lib/routes';
import { useAuth } from '../../../../../authContext';
import useColumnDateRange from '../../components/useColumnDateRange';
import useColumnSearch from '../../../../../hooks/useColumnSearch';

const formationDomains = [
  { text: 'Bâtiment, modélisation (NSF 230)', value: 'BATIMENT' },
  { text: 'Impression, édition, graphisme (NSF 322)', value: 'IMPRESSION' },
  { text: 'Informatique, développement (NSF 326)', value: 'INFORMATIQUE' },
  { text: 'Communication digitale et web (NSF 320)', value: 'COMMUNICATION' },
];

const realizationMethod = [
  { text: 'Présentiel', value: 'PRESENTIEL' },
  { text: 'Télé-présentiel', value: 'DISTANCIEL' },
  { text: 'Mixte', value: 'MIXTE' },
];

const steps = [
  { text: 'Envoyé', value: 'ENVOYÉ' },
  { text: 'Première relance', value: 'RELANCE_1' },
  { text: 'Deuxième relance', value: 'RELANCE_2' },
  { text: 'Troisième relance', value: 'RELANCE_3' },
  { text: 'Complété', value: 'COMPLÉTÉ' },
  { text: 'Programmé', value: 'PROGRAMMÉ' },
];

const SatisfactionReportList = ({ data, deleteReport, disabled, mutate }) => {
  const { data: agencies, isAgenciesValidating } = useSWR(agencyRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { getColumnDateRangeProps } = useColumnDateRange();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const columns = [
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      width: 100,
      filters: [
        { text: 'Individuel', value: 'INDIVIDUAL' },
        { text: 'Entreprise', value: 'COMPANY' },
      ],
      onFilter: (value, record) => record.type === value,
      render: (record) => <Tag>{record === 'INDIVIDUAL' ? 'Individuel' : 'Entreprise'}</Tag>,
    },
    {
      title: 'Nom',
      key: 'lastName',
      sorter: (a, b) => sort(a, b, 'student.lastName'),
      dataIndex: ['student', 'lastName'],
      ...getColumnSearchProps('student.lastName'),
      render: (record) => record.toUpperCase(),
    },
    {
      title: 'Prénom',
      key: 'firstName',
      sorter: (a, b) => sort(a, b, 'student.firstName'),
      dataIndex: ['student', 'firstName'],
      ...getColumnSearchProps('student.firstName'),
      render: (record) => capitalize(record),
    },
    {
      title: 'Domaine de formation',
      key: 'formationDomain',
      dataIndex: ['student', 'formationDomain'],
      filters: formationDomains,
      onFilter: (value, record) => record.student.formationDomain === value,
      sorter: (a, b) => sort(a, b, 'student.formationDomain'),
      render: (record) => {
        const match = formationDomains.find((domain) => domain.value === record);

        return match ? match.text : <Typography.Text type="danger">Domaine introuvable</Typography.Text>;
      },
    },
    {
      title: 'Modalité de réalisation',
      key: 'realizationMethod',
      dataIndex: ['student', 'realizationMethod'],
      filters: realizationMethod,
      onFilter: (value, record) => record.student.realizationMethod === value,
      sorter: (a, b) => sort(a, b, 'student.realizationMethod'),
      render: (record) => {
        const match = realizationMethod.find((method) => method.value === record);

        return match ? match.text : <Typography.Text type="danger">Modalité introuvable</Typography.Text>;
      },
    },
    {
      title: 'Dates de fin de formation',
      key: 'formationDates',
      dataIndex: ['student', 'formationEndAt'],
      align: 'center',
      width: 200,
      ...getColumnDateRangeProps('student.formationEndAt'),
      render: (record) => moment(record).format('DD/MM/YYYY'),
    },
    {
      title: 'Centre',
      key: 'agency',
      filters: agencies?.data
        .sort((a, b) => sort(a, b, 'city'))
        .map((agency) => ({ text: agency.city, value: agency._id })),
      onFilter: (value, record) => record.student.agency._id === value,
      sorter: (a, b) => sort(a, b, 'student.agency.city'),
      dataIndex: ['student', 'agency', 'city'],
      render: (record) => (record ? record : <Typography.Text type="danger">Centre introuvable</Typography.Text>),
    },
    {
      title: 'Statut',
      key: 'step',
      dataIndex: 'step',
      width: 100,
      filters: steps,
      onFilter: (value, record) => {
        if (record.step) {
          return record.step === value;
        } else {
          return value === 'PROGRAMMÉ';
        }
      },
      render: (record) => {
        const match = steps.find((step) => step.value === record);

        return match ? (
          <Tag
            color={match.value !== 'COMPLÉTÉ' ? 'processing' : 'success'}
            style={{ width: '100%', textAlign: 'center' }}
          >
            {match.text}
          </Tag>
        ) : (
          <Tag style={{ width: '100%', textAlign: 'center' }}>Programmé</Tag>
        );
      },
    },
    ...(checkAuthorization(user, 'formao', 'satisfactionReports', 'delete') ||
    checkAuthorization(user, 'formao', 'satisfactionReports', 'download')
      ? [
          {
            key: 'actions',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (record) => (
              <Space>
                {checkAuthorization(user, 'formao', 'satisfactionReports', 'download') && (
                  <Tooltip title="Consulter le PDF" destroyTooltipOnHide={{ keepParent: false }}>
                    <Button
                      className="gray-text"
                      disabled={!record.report.date}
                      onClick={() => downloadPdf(record)}
                      icon={<FilePdfOutlined />}
                    />
                  </Tooltip>
                )}
                {checkAuthorization(user, 'formao', 'satisfactionReports', 'delete') && (
                  <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
                    <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(record._id)}>
                      <Button className="gray-text" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                )}
              </Space>
            ),
          },
        ]
      : []),
  ];

  return (
    <Table
      dataSource={data?.items || []}
      columns={columns}
      pagination={false}
      bordered
      rowKey={(row) => row._id}
      size="small"
      disabled={disabled || !data?.items}
    />
  );
};

export default SatisfactionReportList;
