import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { Row, Col, Form, Input, Typography } from 'antd';

const CompanyStep = ({ disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
          </ul>
        </blockquote>
      </Typography.Paragraph>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Nom de l'entreprise"
            validateStatus={errors?.companyName?.message && 'error'}
            help={errors?.companyName?.message}
            required
          >
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple: Arinfo" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Nom du tuteur"
            validateStatus={errors?.companyTutorLastName?.message && 'error'}
            help={errors?.companyTutorLastName?.message}
            required
          >
            <Controller
              name="companyTutorLastName"
              control={control}
              render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple: Doe" />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Prénom du tuteur"
            validateStatus={errors?.companyTutorFirstName?.message && 'error'}
            help={errors?.companyTutorFirstName?.message}
            required
          >
            <Controller
              name="companyTutorFirstName"
              control={control}
              render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple: John" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Fonction du tuteur"
            validateStatus={errors?.companyTutorFunction?.message && 'error'}
            help={errors?.companyTutorFunction?.message}
            required
          >
            <Controller
              name="companyTutorFunction"
              control={control}
              render={({ field }) => (
                <Input {...field} disabled={disabled} placeholder="Exemple: Chargé de communication" />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Email du tuteur"
            validateStatus={errors?.companyTutorEmail?.message && 'error'}
            help={errors?.companyTutorEmail?.message}
            required
          >
            <Controller
              name="companyTutorEmail"
              control={control}
              render={({ field }) => (
                <Input {...field} disabled={disabled} type="email" placeholder="Exemple: john.doe@mail.fr" />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Téléphone du tuteur"
            validateStatus={errors?.companyTutorPhone?.message && 'error'}
            help={errors?.companyTutorPhone?.message}
          >
            <Controller
              name="companyTutorPhone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(event) => field.onChange(event.target.value.replace(/[^0-9]/g, ''))}
                  disabled={disabled}
                  maxLength={10}
                  allowClear
                  placeholder="Exemple: 0607080910"
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CompanyStep;
